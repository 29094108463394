:root {
  --color-primary: #2584ff;
  --color-accent: #1abc9c;
  --color-headings: #1b0760;
  --color-body: #918ca4;
}

*, :after, :before {
  box-sizing: border-box;
}

::selection {
  background: var(--color-primary);
  color: #fff;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

img {
  width: 100%;
}

body {
  color: var(--color-body);
  background-color: #fff;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
}

h1, h2, h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  font-size: 5.3rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.3;
}

p {
  margin-top: 0;
}

.background-0 {
  background-color: #fff;
  background-image: linear-gradient(0deg, #fff 0%, #cfd2d2 100%);
}

@media screen and (width >= 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

a {
  text-decoration: none;
}

.list {
  padding-left: 0;
  list-style: none;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon--white {
  fill: #fff;
}

.icon--dark {
  fill: #000;
}

.btn {
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  border: 0;
  border-radius: 40px;
  outline: 0;
  margin: 1rem 0;
  padding: 2rem 4vw;
  font-size: 1.8rem;
  font-weight: 600;
}

.btn .icon {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #000;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media screen and (width >= 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}

.grid {
  display: grid;
}

@media screen and (width >= 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.collapsible__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__chevron {
  transition: transform .3s;
  transform: rotate(-90deg);
}

.collapsible__content {
  opacity: 0;
  max-height: 0;
  transition: max-height .3s linear, opacity .3s linear;
  overflow: hidden;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  opacity: 1;
  max-height: 100vh;
}

.collapsible__arrow {
  cursor: pointer;
  color: #434344;
  font-size: 2.7rem;
  transition: transform .3s linear;
  transform: rotate(0);
}

.collapsible--expanded .collapsible__arrow {
  transform: rotate(90deg);
}

.block {
  --padding-vertical: 5rem;
  padding: var(--padding-vertical) 2rem;
}

.block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block__heading {
  color: #000;
  margin-top: 0;
  font-size: 4rem;
  line-height: 1.3;
}

.block--dark {
  color: #7b858b;
  background: #000;
}

.block--dark h1, .block--dark h2, .block--dark h3 {
  color: #fff;
}

.block--skewed-left {
  clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  padding-bottom: 9rem;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.nav {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.nav__list {
  width: 100%;
  margin: 0;
}

.nav__item {
  border-bottom: 1px solid #222;
  padding: .5rem 1rem;
  font-size: 1.7rem;
}

.nav__item:last-of-type {
  border-bottom: none;
}

.nav__item > a {
  color: #434344;
  transition: color .3s;
}

.nav__item > a:hover {
  color: #686869;
}

.nav__toggler {
  opacity: .5;
  cursor: pointer;
  transition: box-shadow .15s;
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0 0 0 3px #666;
}

.nav__brand {
  width: 70px;
}

.nav__image {
  color: #000;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media screen and (width >= 768px) {
  .nav__toggler {
    display: none;
  }

  .nav__list {
    opacity: 1;
    width: auto;
    max-height: 100%;
    font-size: 1.6rem;
    display: flex;
  }

  .nav__item {
    border: 0;
  }
}

.hero {
  padding-top: 10rem;
  padding-bottom: 14rem;
}

.hero__tagline {
  color: #434344;
  letter-spacing: 1px;
  margin: 4rem 0 6rem;
  font-size: 1.5rem;
  line-height: 1.7;
}

.hero__content {
  align-self: center;
}

.hero__image {
  border-radius: 1rem;
  max-width: 300px;
  margin-top: 3rem;
}

.hero__picture {
  place-self: center;
}

.hero__header {
  column-gap: 4rem;
}

@media screen and (width >= 768px) {
  .hero__content {
    text-align: left;
    align-self: center;
  }

  .hero__tagline {
    margin: 2rem 0 5rem;
  }

  .hero__image {
    max-width: 400px;
    margin-top: 0;
  }
}

.about {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-bottom: 5rem;
  display: flex;
}

.about__image {
  border-radius: 1rem;
  width: 200px;
  height: 200px;
  box-shadow: 0 5px 15px #00000026;
}

.about__header {
  text-align: center;
  margin-bottom: 3rem;
}

.about__mistake {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media screen and (width >= 768px) {
  .about {
    flex-direction: row;
  }
}

.feature {
  gap: 4rem 2rem;
  margin: 12rem 0;
}

.feature:first-of-type {
  margin-top: 6rem;
}

.feature__content {
  text-align: center;
}

.feature__heading {
  color: #000;
  margin: 1rem 0 3rem;
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (width >= 768px) {
  .feature:nth-of-type(2n) .feature__content {
    order: 2;
  }
}

.feature__image {
  max-width: 200px;
}

.feature picture {
  justify-content: center;
  align-items: center;
  display: flex;
}

.feature__header {
  color: #000;
  text-align: center;
  margin-bottom: 2rem;
}

.feature__text {
  text-align: center;
  margin-bottom: 10rem;
}

.numbers__header {
  text-align: center;
  margin-bottom: 3rem;
}

.numbers__container {
  justify-content: space-evenly;
  margin-top: 8rem;
  padding-bottom: 4rem;
  display: flex;
}

.numbers__div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.numbers__img {
  width: 70px;
}

.numbers__number {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.numbers__text {
  font-size: 1.2rem;
}

@media screen and (width >= 768px) {
  .numbers__number {
    font-size: 3rem;
  }

  .numbers__text {
    font-size: 1.5rem;
  }
}

.price__info {
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
  font-size: 1.5rem;
  display: flex;
}

.price__info-div {
  border-radius: 3rem;
  width: 400px;
  padding: 3rem;
}

.price__info--white {
  color: #000;
  background: #fff;
  margin-bottom: 5rem;
}

.price__info--black {
  color: #fff;
  background: #000;
}

.price__info-icon {
  margin-right: 2rem;
  font-size: 3rem;
}

.price__info-icongray {
  color: #393939;
}

.price__info--blue {
  color: #1abc9c;
}

.price__info-number {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 5rem;
}

.price__info-month {
  font-size: 1.4rem;
}

.price__info-btn {
  border-radius: 2rem;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 3rem;
  padding: 1.5rem 1rem;
  display: inline-block;
}

.price__info-ul {
  list-style: none;
}

.price__info-ul--primary i {
  color: #1abc9c;
}

.price__info-ul i {
  margin-right: 2rem;
}

.price__info-ul li {
  margin-bottom: 1rem;
}

.price__plan-info {
  text-align: center;
  color: #1f1f1f;
  margin-top: 7rem;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.5rem;
}

.price__block {
  background: #cfd2d2;
}

.price__btn-gray {
  color: #fff;
  background: #393939;
}

.price__btn-blue {
  color: #fff;
  background: #1abc9c;
}

@media screen and (width >= 1024px) {
  .price__plan-info {
    padding-top: 1rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@media screen and (width >= 955px) {
  .price__info {
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: 4rem;
  }
}

.faq__header {
  color: #000;
  text-align: center;
  margin-bottom: 2rem;
}

.faq__heading {
  color: #000;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.faq__section {
  margin-top: 1rem;
}

.faq__text {
  color: #7b858b;
  font-size: 1.6rem;
}

.faq__email {
  color: red;
  font-weight: bold;
}

@media screen and (width >= 768px) {
  .faq__text {
    font-size: 1.8rem;
  }
}

.faq-header-margin {
  padding-bottom: 8rem;
}

.footer {
  background: #000;
  padding-top: 6rem;
}

.footer a {
  color: #7b858b;
  transition: color .3s;
}

.footer a:hover {
  color: #fff;
}

.footer__section {
  border-bottom: 1px solid #393939;
  padding: 2rem;
}

.footer__section .list {
  margin: 0;
}

.footer__heading {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.5;
}

.footer__brand {
  text-align: center;
  margin-top: 5rem;
}

.footer__brand img {
  max-width: 70px;
  padding-bottom: 2rem;
}

.footer__copyright {
  font-size: 2.1rem;
}

.footer__copyright a:hover, .footer__copyright a:visited, .footer__copyright a:link, .footer__copyright a:active {
  color: #7b858b;
}

.footer-p {
  margin-bottom: 0;
}

.footer_image {
  color: #fff;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  display: flex;
}

@media screen and (width >= 768px) {
  .footer {
    font-size: 1.5rem;
  }

  .footer__sections {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }

  .footer .collapsible__chevron {
    display: none;
  }

  .footer .collapsible__content {
    opacity: 1;
    max-height: 100%;
  }

  .footer__brand {
    order: -1;
    margin-top: 1rem;
  }

  .footer__copyright {
    font-size: 1.5rem;
  }

  .footer__section {
    border: 0;
  }

  .footer__heading {
    font-size: 1.6rem;
  }
}
/*# sourceMappingURL=index.a99b8f6f.css.map */
