:root {
  --color-primary: #2584ff;
  --color-accent: #1abc9c;
  --color-headings: #1b0760;
  --color-body: #918ca4;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Typography */
::selection {
  background: var(--color-primary);
  color: #fff;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

img {
  width: 100%;
}

body {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  color: var(--color-body);
  background-color: #ffffff;
}

h1,
h2,
h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  font-size: 5.3rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.3;
}

p {
  margin-top: 0;
}

.background-0 {
  background-color: #ffffff;
  background-image: linear-gradient(0deg, #ffffff 0%, #cfd2d2 100%);
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

/* Links */
a {
  text-decoration: none;
}

/* Lists */
.list {
  list-style: none;
  padding-left: 0;
}

/* Icons */
.icon {
  width: 40px;
  height: 40px;
}

.icon--white {
  fill: #fff;
}

.icon--dark {
  fill: #000;
}

/* Buttons */
.btn {
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1rem 0;
  outline: 0;
  padding: 2rem 4vw;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #000;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media screen and (min-width: 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}

/* Grids */
.grid {
  display: grid;
}

@media screen and (min-width: 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Collapsibles */
.collapsible__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__chevron {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s linear, opacity 0.3s linear;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

.collapsible__arrow {
  font-size: 2.7rem;
  cursor: pointer;
  color: rgb(67, 67, 68);
  transform: rotate(0);
  transition: transform 0.3s linear;
}

.collapsible--expanded .collapsible__arrow {
  transform: rotate(90deg);
}

/* Blocks */
.block {
  --padding-vertical: 5rem;
  padding: var(--padding-vertical) 2rem;
}

.block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block__heading {
  color: #000;
  margin-top: 0;
  font-size: 4rem;
  line-height: 1.3;
}

.block--dark {
  background: #000;
  color: #7b858b;
}

.block--dark h1,
.block--dark h2,
.block--dark h3 {
  color: #fff;
}

.block--skewed-left {
  padding-bottom: 9rem;
  clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

/* Navigation Bar */
.nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  align-items: center;
}

.nav__list {
  width: 100%;
  margin: 0;
}

.nav__item {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #222;
  font-size: 1.7rem;
}

.nav__item:last-of-type {
  border-bottom: none;
}

.nav__item > a {
  color: #434344;
  transition: color 0.3s;
}

.nav__item > a:hover {
  color: #686869;
}

.nav__toggler {
  opacity: 0.5;
  transition: box-shadow 0.15s;
  cursor: pointer;
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
  box-shadow: 0 0 0 3px #666;
  border-radius: 5px;
}

.nav__brand {
  width: 70px;
}

.nav__image {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .nav__toggler {
    display: none;
  }

  .nav__list {
    width: auto;
    display: flex;
    font-size: 1.6rem;
    max-height: 100%;
    opacity: 1;
  }

  .nav__item {
    border: 0;
  }
}

/* header */

/* hero */
.hero {
  padding-top: 10rem;
  padding-bottom: 14rem;
}

.hero__tagline {
  font-size: 1.5rem;
  color: #434344;
  letter-spacing: 1px;
  line-height: 1.7;
  margin: 4rem 0 6rem;
}

.hero__content {
  align-self: center;
}

.hero__image {
  border-radius: 1rem;
  max-width: 300px;
  margin-top: 3rem;
}

.hero__picture {
  align-self: center;
  justify-self: center;
}

.hero__header {
  column-gap: 4rem;
}

@media screen and (min-width: 768px) {
  .hero__content {
    text-align: left;
    align-self: center;
  }

  .hero__tagline {
    margin: 2rem 0 5rem;
  }

  .hero__image {
    max-width: 400px;
    margin-top: 0;
  }
}

/* about */
.about {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.about__image {
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  height: 200px;
  width: 200px;
}

.about__header {
  text-align: center;
  margin-bottom: 3rem;
}

.about__mistake {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .about {
    flex-direction: row;
  }
}

/* feature */
.feature {
  gap: 4rem 2rem;
  margin: 12rem 0;
}

.feature:first-of-type {
  margin-top: 6rem;
}

.feature__content {
  text-align: center;
}

.feature__heading {
  margin: 1rem 0 3rem;
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .feature:nth-of-type(even) .feature__content {
    order: 2;
  }
}

.feature__image {
  max-width: 200px;
}

.feature picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature__header {
  color: #000;
  text-align: center;
  margin-bottom: 2rem;
}

.feature__text {
  text-align: center;
  margin-bottom: 10rem;
}

/* numbers */
.numbers__header {
  text-align: center;
  margin-bottom: 3rem;
}

.numbers__container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8rem;
  padding-bottom: 4rem;
}

.numbers__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.numbers__img {
  width: 70px;
}

.numbers__number {
  font-weight: bold;
  color: #fff;
  font-size: 2rem;
}

.numbers__text {
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .numbers__number {
    font-size: 3rem;
  }

  .numbers__text {
    font-size: 1.5rem;
  }
}

/* price */
.price__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
  font-size: 1.5rem;
}

.price__info-div {
  border-radius: 3rem;
  padding: 3rem;
  width: 400px;
}

.price__info--white {
  background: #fff;
  color: #000;
  margin-bottom: 5rem;
}

.price__info--black {
  background: #000;
  color: #fff;
}

.price__info-icon {
  margin-right: 2rem;
  font-size: 3rem;
}

.price__info-icongray {
  color: #393939;
}

.price__info--blue {
  color: #1abc9c;
}

.price__info-number {
  font-size: 5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.price__info-month {
  font-size: 1.4rem;
}

.price__info-btn {
  display: inline-block;
  margin-top: 4rem;
  margin-bottom: 3rem;
  border-radius: 2rem;
  width: 100%;
  padding: 1.5rem 1rem;
}

.price__info-ul {
  list-style: none;
}

.price__info-ul--primary i {
  color: #1abc9c;
}

.price__info-ul i {
  margin-right: 2rem;
}

.price__info-ul li {
  margin-bottom: 1rem;
}

.price__plan-info {
  font-size: 1.5rem;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 7rem;
  color: #1f1f1f;
}

.price__block {
  background: #cfd2d2;
}

.price__btn-gray {
  background: #393939;
  color: #fff;
}

.price__btn-blue {
  background: #1abc9c;
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .price__plan-info {
    padding-top: 1rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@media screen and (min-width: 955px) {
  .price__info {
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    align-items: start;
  }
}

/* faq */
.faq__header {
  color: #000;
  text-align: center;
  margin-bottom: 2rem;
}

.faq__heading {
  color: #000;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.faq__section {
  margin-top: 1rem;
}

.faq__text {
  color: #7b858b;
  font-size: 1.6rem;
}

.faq__email {
  color: red;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .faq__text {
    font-size: 1.8rem;
  }
}

.faq-header-margin {
  padding-bottom: 8rem;
}

/* footer */
.footer {
  background: #000;
  padding-top: 6rem;
}

.footer a {
  color: #7b858b;
  transition: color 0.3s;
}

.footer a:hover {
  color: #fff;
}

.footer__section {
  padding: 2rem;
  border-bottom: 1px solid #393939;
}

.footer__section .list {
  margin: 0;
}

.footer__heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.5;
}

.footer__brand {
  margin-top: 5rem;
  text-align: center;
}

.footer__brand img {
  max-width: 70px;
  padding-bottom: 2rem;
}

.footer__copyright {
  font-size: 2.1rem;
}

.footer__copyright a:hover,
.footer__copyright a:visited,
.footer__copyright a:link,
.footer__copyright a:active {
  color: #7b858b;
}

.footer-p {
  margin-bottom: 0;
}

.footer_image {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

@media screen and (min-width: 768px) {
  .footer {
    font-size: 1.5rem;
  }
  .footer__sections {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }

  .footer .collapsible__chevron {
    display: none;
  }

  .footer .collapsible__content {
    opacity: 1;
    max-height: 100%;
  }

  .footer__brand {
    order: -1;
    margin-top: 1rem;
  }

  .footer__copyright {
    font-size: 1.5rem;
  }

  .footer__section {
    border: 0;
  }

  .footer__heading {
    font-size: 1.6rem;
  }
}
